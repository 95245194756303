import { template as template_e5bb305cdc9148b88e4740855bcbe559 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e5bb305cdc9148b88e4740855bcbe559(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
