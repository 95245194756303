import { template as template_347fa66b52bc44348abddbb8eb85507e } from "@ember/template-compiler";
const WelcomeHeader = template_347fa66b52bc44348abddbb8eb85507e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
